import { GoogleReCaptchaProvider, } from 'react-google-recaptcha-v3';

import { BotChecker, RECAPTCHA_SITE_PUBLIC_KEY } from './BotChecker';

export const AreYouARobot = () => {

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_PUBLIC_KEY.V3()}>
      <BotChecker />
    </GoogleReCaptchaProvider>
  );
};

export default AreYouARobot;
