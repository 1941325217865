import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryString = () => {
  const [searchParamsState] = useSearchParams();

  const searchParams = useMemo(
    () => {
      const queryParams = {};
      for (const [key, value] of searchParamsState.entries()) {
        queryParams[key] = value;
      }
      return queryParams;
    },
    [searchParamsState]
  );
  return searchParams;
};
